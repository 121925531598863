import "./skeletonCardStyles.scss";

export const SkeletonCard = () => {
    return (
        <div className="w-100 card skeleton_card_component pb-2">
            <div className="img-fluid img_car"></div>
            <div className="card-body d-flex flex-column justify-content-evenly">
                <div className="flex-grow overflow-hidden">
                    <span className="d-block mb-2 loading_effec" style={{ width: "100%", height: "20px" }}></span>
                    <span className="d-block mt-2 loading_effec" style={{ width: "80%", height: "20px" }}></span>
                </div>
                <div className="d-block mt-2 loading_effec" style={{ height: "10px", width: "50px" }}></div>

                <div className="w-100 row justify-content-around mt-3" style={{ fontSize: ".9em", margin: "0 auto" }}>
                    <div className="col-3 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/calendar.svg"
                            alt="calendario"
                            title="calendario"
                            className="img-fluid img_icon me-2"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span className="d-block loading_effec" style={{ height: "8px", width: "30px" }}></span>
                    </div>
                    <div className="col-4 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/odometer.svg"
                            alt="kilometraje"
                            title="kilometraje"
                            className="img-fluid img_icon me-2"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span className="d-block loading_effec" style={{ height: "8px", width: "60px" }}></span>
                    </div>
                    <div className="col-4 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/transmission.svg"
                            alt="transmision"
                            title="transmision"
                            className="img-fluid img_icon me-1"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span className="d-block loading_effec" style={{ height: "8px", width: "60px" }}></span>
                    </div>
                </div>
                <div className="mt-4 d-flex align-items-center justify-content-between">
                    <span className="loading_effec" style={{ height: "30px", width: "55%" }}></span>
                    <span className="loading_effec-button"></span>
                </div>
            </div>
        </div>
    );
};
