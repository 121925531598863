import { FC, useState, useRef } from "react";
import { CardCarousel } from "../cardCarousel/cardCarousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carouselStyles.scss";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { SegurosModal } from "../segurosModal/segurosModal";
interface DetailForm {
    form: any;
}

export const CarouselSeguros: FC<{
    cars: any;
    carsRelated?: boolean;
    setOpenModal: (e: boolean) => void;
    setDetailForm: (e: DetailForm) => void;
}> = ({ cars, carsRelated = false, setOpenModal, setDetailForm }) => {
    const [indexCard, setIndexCard] = useState<number>(0);
    const [showForm, setShowForm] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const carouselRef = useRef(null);

    const previous = () => {
        if (carouselRef.current && indexCard !== 0) {
            carouselRef.current.goToSlide(indexCard - 1);
            setIndexCard(indexCard - 1);
        }
    };

    const next = () => {
        if (carouselRef.current && indexCard + 1 !== cars?.length) {
            carouselRef.current.goToSlide(indexCard + 1);
            setIndexCard(indexCard + 1);
        }
    };

    const goToIndex = (index: number) => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(index);
            setIndexCard(index);
        }
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1300 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1300, min: 767 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        },
    };

    return (
        <>
            <div className={"p-4"}>
                <Carousel
                    partialVisible
                    ref={carouselRef}
                    responsive={responsive}
                    removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                    beforeChange={(currentSlide, _) => {
                        setIndexCard(currentSlide);
                    }}
                >
                    {cars?.map((car: any, i: number) => {
                        return (
                            <div className={"mb-2 me-3 position-relative"}>
                                <div
                                    className="d-flex"
                                    style={{ borderWidth: 2, borderColor: "#ec671b", borderStyle: "solid" }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                borderWidth: 1,
                                                borderColor: "red",
                                                background: "#ec671b",
                                                position: "absolute",
                                                left: 0,
                                                right: 0,
                                            }}
                                        >
                                            <h3 className="text-light ms-3">{car.title}</h3>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 0,
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                paddingBottom: 12,
                                            }}
                                        >
                                            <div></div> <p className={" ps-3 pt-4 "}>{car.description}</p>
                                            <div className="w-100 d-flex justify-content-center">
                                                <button
                                                    className="btn btn_cyan mt-2 "
                                                    onClick={() => {
                                                        setShowForm(true);
                                                        setSelectedId(i + 1);
                                                    }}
                                                >
                                                    Cotiza Ahora
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                            backgroundColor: "#3498db",
                                            clipPath: "polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)",
                                        }}
                                    >
                                        <img
                                            // src="/assets/services/auto.webp"
                                            src={car.image}
                                            alt={car.title}
                                            style={{ width: 200, height: 200, transform: "scaleX(-1)" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
                <div className="container_nav w-80 d-flex">
                    <div className={`arrow_slider_card me-1 ${indexCard === 0 ? "disabled" : null}`} onClick={previous}>
                        <AiOutlineArrowLeft />
                    </div>
                    <div
                        className={`arrow_slider_card ms-1 ${indexCard === cars?.length - 1 ? "disabled" : null}`}
                        onClick={next}
                    >
                        <AiOutlineArrowRight />
                    </div>
                    <div className="container_item_card d-flex flex-wrap justify-content-around align-items-center">
                        {cars?.map((car: any, i: number) => {
                            return (
                                <div
                                    className={`item_card ${indexCard === i ? "active" : "disabled"}`}
                                    key={i}
                                    onClick={() => goToIndex(i)}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div style={{ position: "relative" }}>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 1 ? "auto" : 0,
                        height: selectedId == 1 ? "auto" : 1,
                        paddingTop: selectedId == 1 ? 20 : 0,
                        textAlign: "center",
                        overflow: "hidden",
                    }}
                    className=""
                >
                    {cars[0]?.modal}
                </div>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 2 ? "auto" : 0,
                        height: selectedId == 2 ? "auto" : 1,
                        paddingTop: selectedId == 2 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[1]?.modal}
                </div>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 3 || selectedId == 2 || selectedId == 4 ? "auto" : 0,
                        height: selectedId == 3 || selectedId == 2 || selectedId == 4 ? "auto" : 1,
                        paddingTop: selectedId == 3 || selectedId == 2 || selectedId == 4 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[2]?.modal}
                </div>

                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 5 ? "auto" : 0,
                        height: selectedId == 5 ? "auto" : 1,
                        paddingTop: selectedId == 5 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[4]?.modal}
                </div>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 6 ? "auto" : 0,
                        height: selectedId == 6 ? "auto" : 1,
                        paddingTop: selectedId == 6 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[5]?.modal}
                </div>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 7 ? "auto" : 0,
                        height: selectedId == 7 ? "auto" : 1,
                        paddingTop: selectedId == 7 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[6]?.modal}
                </div>
                <div
                    style={{
                        backgroundColor: "#1e1e1e",
                        width: selectedId == 8 ? "auto" : 0,
                        height: selectedId == 8 ? "auto" : 1,
                        paddingTop: selectedId == 8 ? 20 : 0,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    {cars[7]?.modal}
                </div>
            </div>
        </>
    );
};
