import { useEffect, useRef, useState } from "react";

const useHogar = () => {
    const formRefHogar = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scriptContent = `
            !function(a,m,o,c,r,m){
                a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}};
                a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])};
                a[o+r]({id:"1430775",hash:"dada8cf9001d820363fba03db079f428",locale:"es"});
                a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])};
            }(window,0,"amo_forms_","params","load","loaded");
        `;

        const inlineScript = document.createElement("script");
        inlineScript.innerHTML = scriptContent;
        document.body.appendChild(inlineScript);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1430775";
        kommoScript.async = true;
        kommoScript.src = `https://forms.kommo.com/forms/assets/js/amoforms.js?${Date.now()}`;

        const timeoutId = setTimeout(() => {
            if (formRefHogar.current) {
                formRefHogar.current.innerHTML = ""; // Limpia antes de insertar
                formRefHogar.current.appendChild(kommoScript);
            }
        }, 500);

        return () => {
            document.body.removeChild(inlineScript);
            clearTimeout(timeoutId);
        };
    }, []);

    return { formRefHogar };
};

export default useHogar;
