import "./CheckRecoveryCodePageStyles.scss";
import { FC, FormEvent, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Validators from "../../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Icons from "../../../assets/Icons";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";

import { confirmThePasswordReset } from "../../../../utils/Firebase";
import Button from "../../../components/Button";

const CheckRecoveryCodePage: FC<{}> = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { email } = useParams<{ email: string }>();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [searchParams] = useSearchParams();
    let oobCode: string | null = searchParams.get("oobCode") || null;

    const _handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            if (oobCode) {
                await confirmThePasswordReset(oobCode, watch()?.confirm_password);
                setShowSuccessMessage(true);
            } else {
                alert("Something is wrong; try again later!");
            }
        } catch (error: any) {
            if (error.code === "auth/invalid-action-code") {
                alert("Something is wrong; try again later.");
            }
            console.log(error.message);
        }
    };

    return (
        <div className="check_recovery_code_page">
            <div className="container container-page">
                <div className="row d-flex flex-md-row-reverse">
                    <div className="col-md-6 px-0 px-md-3 mb-4">
                        <img
                            src="/assets/pages/signIn/bg_sign_in.jpeg"
                            className="img-fluid h-100 object_cover img-cover"
                            alt="login"
                            title="login"
                        />
                    </div>
                    <div className="col-md-1"></div>
                    {showSuccessMessage ? (
                        <div className="col-md-4 content_page">
                            <img
                                width={80}
                                height={80}
                                src="/assets/icons_contact/check_true.svg"
                                alt=""
                                className="mt-4"
                                style={{ margin: "auto", alignSelf: "center" }}
                            />
                            <h2 className="text-center mt-4">Constraseña restablecida con exito!</h2>
                            <p
                                className="text-center mt-2"
                                style={{ fontSize: "20px", fontWeight: "bold", color: "#ec671b" }}
                            >
                                Ingresa ya a la aplicación con tu nueva contraseña
                            </p>
                        </div>
                    ) : (
                        <div className="col-md-4 content_page">
                            <h2 className="text_bold mb-3">Recupera tu contraseña</h2>
                            {/* <p>Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. </p> */}
                            <form onSubmit={handleSubmit(_handleSubmit)}>
                                {/* <div className="form-group my-3">
                                <label className="mandatory">Código</label>
                                <input
                                    type="text"
                                    placeholder="Código"
                                    className="form-control"
                                    {...register(
                                        "code",
                                        Validators({
                                            minLength: 6,
                                            maxLength: 6,
                                            required: true,
                                        })
                                    )}
                                />
                                <ErrorMessage as="aside" errors={errors} name="code" />
                            </div> */}
                                <div className="form-group my-3">
                                    <label className="mandatory">Contraseña</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder={"Contraseña"}
                                        {...register("password", Validators({ isPassword: true, required: true }))}
                                    />
                                    <ErrorMessage as="aside" errors={errors} name="password" />
                                </div>
                                <div className="form-group my-3">
                                    <label className="mandatory">Confirmar Contraseña</label>
                                    <input
                                        type="password"
                                        {...register(
                                            "confirm_password",
                                            Validators({
                                                isPassword: true,
                                                required: true,
                                                minLength: 6,
                                                validate: (val: string) => {
                                                    console.log("validator pass", val, watch("password"));
                                                    if (watch("password") != val) {
                                                        return "Las contraseñas no coinciden";
                                                    }
                                                },
                                            })
                                        )}
                                        className="form-control"
                                        placeholder="Confirmar contraseña"
                                    />
                                    <ErrorMessage as="aside" errors={errors} name="confirm_password" />
                                </div>
                                <div className="w-100 d-flex justify-content-center mt-2">
                                    <Button
                                        label="ACTUALIZAR CONTRASEÑA"
                                        iconRight={<Icons.PersonRounded width={32} />}
                                        type="submit"
                                    />
                                </div>
                            </form>
                            {/* <SocialLoginComponent /> */}
                        </div>
                    )}

                    <div className="col-md-1"></div>
                </div>
            </div>
        </div>
    );
};

export default CheckRecoveryCodePage;
