import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SLOGAN } from "../../../utils/Contants";
import { routes } from "../../routes/RoutesComponent";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./FooterComponentStyles.scss";
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";

const FooterComponent: FC<{}> = () => {
    const [termAndConditionsLinks, setTermAndConditionsLinks] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await fetch("https://api.wcar.online/api/terms/no-contents");
                const data = await res.json();
                setTermAndConditionsLinks(data);
            } catch (error) {}
        };
        getData();
    }, []);

    const mapContainerStyle = {
        width: "98%",
        height: "180px",
    };

    const location1 = {
        lat: 4.691959880308249,
        lng: -74.07611493603319,
    };
    const location2 = {
        lat: 4.902005263873653,
        lng: -74.03039745767153,
    };
    const location3 = {
        lat: 4.691388319180716,
        lng: -74.07598815868705,
    };
    const center = {
        lat: (location1.lat + location2.lat) / 2,
        lng: (location1.lng + location2.lng) / 2,
    };

    return (
        <>
            <footer className="col-md-12">
                <div className="enlaces-wrapper">
                    <div className="box-divider">
                        <div className="divider">
                            <p className="title">Menú</p>
                            <ul>
                                <li>
                                    <Link to={routes.aboutUs.relativePath}>Nuestra empresa</Link>
                                </li>
                                <li>
                                    <Link to={routes.buyYourCar.relativePath}>Compra tu carro</Link>
                                </li>
                                <li>
                                    <Link to={routes.sellYourCar.relativePath}>Vende tu carro</Link>
                                </li>
                                <li>
                                    <Link to={routes.services.relativePath}>Financiación</Link>
                                </li>
                                <li>
                                    <Link to={routes.insurance.relativePath}>Seguros</Link>
                                </li>
                                <li>
                                    <Link to={routes.procedures.relativePath}>Trámites</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="divider">
                            <p className="title">Contacto</p>
                            <ul>
                                <li>
                                    <Link to="mailto:contacto@wcar.co">contacto@wcar.co</Link>
                                </li>
                                <li className="address">
                                    <strong>Morato</strong>
                                    <p>
                                        <span>+57 324 4001212</span> • Cll 98a #69B-35
                                    </p>
                                </li>
                                <li className="address">
                                    <strong>Sabana</strong>
                                    <p>
                                        <span>+57 324 4001212</span> • Cra 5 #9-26 sur Cajicá. Torre 3 - Local 3
                                    </p>
                                </li>
                                <li className="address">
                                    <strong>Taller</strong>
                                    <p>Cr 69b #98-28</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container-map">
                        <LoadScript googleMapsApiKey="AIzaSyD8q92si456MOmd_nZIQnL_EcvPteWc2K8">
                            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={9}>
                                <Marker position={location1} />
                                <Marker position={location2} />
                                <Marker position={location3} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div className="divider divider-app">
                        <p className="title"> App mobile wcar</p>
                        <ul>
                            <li>
                                <Link to="/eliminacion-de-cuenta">Eliminación de cuenta</Link>
                            </li>
                            {termAndConditionsLinks.map((termAndConditionsLink, i: number) => {
                                if (termAndConditionsLink.is_app) {
                                    return (
                                        <li key={i}>
                                            <Link to={`/${termAndConditionsLink.url}/${termAndConditionsLink.id}`}>
                                                {termAndConditionsLink.title}
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                            <li>
                                <Link to="/politicas-de-privacidad-para-la-aplicacion-movil-wcar">
                                    wcar política de tratamiento de datos app
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="store-container">
                        <Link to="https://play.google.com/store/search?q=wcar&c=apps">
                            <img
                                className="logo-store"
                                src="/assets/google-play.svg"
                                alt="google"
                                title="google"
                                loading="lazy"
                                decoding="async"
                                width={160}
                                height={60}
                            />
                        </Link>
                        {/* <img className="logo-store" src="/assets/app-store.svg" alt="wcar" title="wcar" /> */}
                    </div>
                    <div className="divider divider-terms-conditions">
                        <p className="title">Términos y condiciones</p>
                        <ul>
                            <li>
                                <Link to="/aviso-de-privacidad-de-datos-para-usuarios-app-wcar/20">
                                    Aviso de privacidad de datos para usuarios
                                </Link>
                            </li>
                            <li>
                                <Link to="/politicas-de-privacidad-de-la-aplicacion-movil-wcar/21">
                                    Políticas de privacidad
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/terminos-y-condiciones-usuarios">Términos y condiciones usuarios</Link>
                            </li> */}
                            {/* <li>
                                <Link to="">Términos y condiciones garantía</Link>
                            </li> */}
                            {/* <li>
                                <Link to="/politicas-de-privacidad-para-la-aplicacion-movil-wcar">
                                    wcar política de tratamiento de datos app
                                </Link>
                            </li>
                            <li>
                                <Link to="/terminos-y-condiciones-campana-wcar-taller---mantenimiento/12">
                                    Términos y condiciones compaña wcar taller-mantenimiento
                                </Link>
                            </li> */}
                            {termAndConditionsLinks.map((termAndConditionsLink, i: number) => {
                                if (!termAndConditionsLink.is_app) {
                                    return (
                                        <li key={i}>
                                            <Link to={`/${termAndConditionsLink.url}/${termAndConditionsLink.id}`}>
                                                {termAndConditionsLink.title}
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                            <li>
                                <Link to={"/politicas-comprador"}>Términos y condiciones comprador</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="secondary-section">
                    <div className="container-logo">
                        <div className="box-logo">
                            <img
                                className="mb-3 logo"
                                src="/assets/logos/horizontal.svg"
                                alt="wcar"
                                title="wcar"
                                loading="lazy"
                                decoding="async"
                                width={70}
                                height={30}
                            />
                            <span className="d-block text-center slogan-text">{SLOGAN}</span>
                        </div>
                        <div className="social-media">
                            <p>Siguenos</p>
                            <div className="sm-enlaces">
                                <Link to="https://www.facebook.com/wcarcolombia?mibextid=ZbWKwL" target="_blank">
                                    <div className="sm-item">
                                        <FaFacebookF size={16} />
                                    </div>
                                </Link>
                                <Link
                                    to="https://www.instagram.com/wcar.oficial?igshid=NGVhN2U2NjQ0Yg%3D%3D"
                                    target="_blank"
                                >
                                    <div className="sm-item">
                                        <FaInstagram size={18} />
                                    </div>
                                </Link>
                                <Link to="https://www.youtube.com/@wcarcolombia?si=SAfvApSkimvFnxHK" target="_blank">
                                    <div className="sm-item">
                                        <FaYoutube size={19} />
                                    </div>
                                </Link>
                                <Link to="https://www.linkedin.com/company/wcarcol/" target="_blank">
                                    <div className="sm-item">
                                        <FaLinkedinIn size={16} />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-map-desk">
                        <LoadScript googleMapsApiKey="AIzaSyD8q92si456MOmd_nZIQnL_EcvPteWc2K8">
                            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={9}>
                                <Marker position={location1} />
                                <Marker position={location2} />
                                <Marker position={location3} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div className="store-container-desk">
                        <Link to="https://play.google.com/store/search?q=wcar&c=apps">
                            <img
                                className="logo-store"
                                src="/assets/google-play.svg"
                                alt="google-play"
                                title="wcar"
                                loading="lazy"
                                decoding="async"
                                width={140}
                                height={60}
                            />
                        </Link>
                        {/* <img className="logo-store" src="/assets/app-store.svg" alt="wcar" title="wcar" /> */}
                    </div>
                </div>

                <div className="divider divider-last">
                    <ul>
                        <li>
                            <Link to="/politica-de-privacidad">Políticas y tratamiento de datos</Link>
                        </li>
                        <li>
                            <Link to="/politicas-comprador">Términos y condiciones comprador</Link>
                        </li>
                        <li>
                            <Link to="/politicas-vendedor">Términos y condiciones vendedor</Link>
                        </li>
                    </ul>
                </div>

                <div className="divider divider-last-desk">
                    <div className="box-industria-comercio">
                        <img
                            src="/assets/logos/industria_y_comercio.png"
                            alt="supertendencia"
                            title="supertendencia"
                            className="img_fluid mb-3 px-5 px-md-0"
                            loading="lazy"
                            decoding="async"
                            width={180}
                            height={40}
                        />
                        <div className="text-center text-md-start">
                            <p className="text-rights">wcar - Todos los derechos reservados © 2024</p>
                        </div>
                        <div className="bg-white" />
                    </div>
                    <ul>
                        <li>
                            <Link to="/politica-de-privacidad">Políticas y tratamiento de datos</Link>
                        </li>
                        <li>
                            <Link to="/politicas-comprador">Términos y condiciones comprador</Link>
                        </li>
                        <li>
                            <Link to="/politicas-vendedor">Términos y condiciones vendedor</Link>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    );
};

export default FooterComponent;
