import "./CardBlogComponentStyles.scss";
import { FC } from "react";
import CardBlogComponentProps from "./CardBlogComponentProps";
import DateParse from "../../../../../utils/DateParse";
import { Link } from "react-router-dom";
import { routes } from "../../../../routes/RoutesComponent";

const CardBlogComponent: FC<CardBlogComponentProps> = ({ blog }) => {
    const month = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(new Date(blog.created_at));
    const day = new Intl.DateTimeFormat("es-ES", { day: "numeric" }).format(new Date(blog.created_at));
    const year = new Intl.DateTimeFormat("es-ES", { year: "numeric" }).format(new Date(blog.created_at));

    return (
        <Link
            to={
                routes.detailedBlog.relativePath +
                `/${blog.url_post ? blog.url_post.replace(/ /g, "-") : blog.title.replace(/ /g, "-")}?id=${blog.id}`
            }
            className="card_blog_component"
        >
            <div className="card">
                <div className="card_body p-3">
                    <img
                        src={blog.photoUrl}
                        alt={blog.title}
                        title={blog.title}
                        className="img-fluid w-100 object_cover img_card_blog"
                    />
                    <div className="tag mt-3 text-capitalize">{blog?.id === 50 ? "Prensa" : blog?.tagName}</div>
                    <h5 className="my-3 text_bold title_card_blog" title={blog.title}>
                        {blog.title?.length < 80 ? blog.title : blog.title.substring(0, 80) + "..."}
                    </h5>
                    <div className="blog_info_user">
                        <span className="text_gray date">{`${month} ${day}, ${year}`}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CardBlogComponent;
