import "./EditProfilePageStyles.scss";
import { FC, useContext, useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import Icons from "../../../assets/Icons";
import { Link, useNavigate } from "react-router-dom";
import HorizontalSteperComponent from "../../../components/horizontalStepper/HorizontalStepperComponent";
import { useForm } from "react-hook-form";
import Validators from "../../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import BrandContext from "../../../../../domain/providers/brand/BrandContext";
import BrandContextType from "../../../../../domain/providers/brand/BrandContextType";
import di from "../../../../../di/DependencyInjection";
import GetModelsByBrandUseCase, {
    GetModelsByBrandUseCaseName,
} from "../../../../../domain/use_cases/brand/GetModelsByBrandUseCase";
import GetModelVersionByModelAndBrandIdUseCase, {
    GetModelVersionByModelAndBrandIdUseCaseName,
} from "../../../../../domain/use_cases/brand/GetModelVersionByModelAndBrandIdUseCase";
import CityContext from "../../../../../domain/providers/city/CityContext";
import CityContextType from "../../../../../domain/providers/city/CityContextType";
import ColorContextType from "../../../../../domain/providers/color/ColorContextType";
import ColorContext from "../../../../../domain/providers/color/ColorContext";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CalculateOfferForCarUseCase, {
    CalculateOfferForCarUseCaseName,
} from "../../../../../domain/use_cases/calculator/CalculateOfferForCarUseCase";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import { routes } from "../../../routes/RoutesComponent";
import { isRight } from "fp-ts/lib/Either";
import ModelEntity from "../../../../../domain/entities/ModelEntity";
import VersionModelEntity from "../../../../../domain/entities/VersionModelEntity";
import GetAllDepartmentsUseCase, {
    GetAllDepartmentsUseCaseName,
} from "../../../../../domain/use_cases/department/GetAllDepartmentsUseCase";
import DepartmentEntity from "../../../../../domain/entities/DepartmentEntity";
import CityEntity from "../../../../../domain/entities/CityEntity";
import GetCitiesByDepartmentIdUseCase, {
    GetCitiesByDepartmentIdUseCaseName,
} from "../../../../../domain/use_cases/city/GetCitiesByDepartmentIdUseCase";
import UserContext from "../../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../../domain/providers/user/UserContextType";
import HostApi from "../../../../../data/settings/HostApi";

const _min_year = 1999;
const EditProfilePage: FC<{}> = () => {
    const navigate = useNavigate();
    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const { brands } = useContext(BrandContext) as BrandContextType;
    const { cities } = useContext(CityContext) as CityContextType;
    const { colors } = useContext(ColorContext) as ColorContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { user } = useContext(UserContext) as UserContextType;

    const [currentStep, setCurrentStep] = useState(0);
    const [models, setModels] = useState<ModelEntity[]>([]);
    const [versions, setVersions] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [citiesByDepartment, setCitiesByDepartment] = useState<CityEntity[]>([]);

    const _handleOnSubmit = (data: any) => {
        _handleSave(data);
    };

    const _handleSave = async (data: any) => {
        setLoading(true);
        try {
            const data3 = {
                name: getValues("userName"),
                email: getValues("email"),
            };
            console.warn(data3);

            const response = await fetch("https://api.wcar.online/api/user/update/", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + HostApi.getToken(),
                },
                body: JSON.stringify({
                    name: getValues("userName"),
                    email: getValues("email"),
                }),
            });

            const data2 = await response.json();
            console.log("data2", data2);

            addToast("Usuario actualizado con éxito.", "success", undefined);
            setLoading(false);
        } catch {
            addToast("Ha ocurrido un error al actualizar el usuario.", "error", undefined);
            setLoading(false);
        }

        // navigate(routes.dateForSell.relativePath, { state: { buyData: data } });
        // const calculated = await di.get<CalculateOfferForCarUseCase>(CalculateOfferForCarUseCaseName).call(data);
        // if (isRight(calculated)) {
        //     addToast("Cotización realizada", "success", undefined);
        //     // navigate(routes.quoteSuccessful.relativePath, { state: { calculated: calculated.right } });
        // } else {
        //     addToast(calculated.left.message ?? "Error creando cotización", "error", undefined);
        //     setLoading(false);
        // }
    };

    const _handleGoBack = () => navigate(-1);

    const getDepartaments = async () => {
        const response = await di.get<GetAllDepartmentsUseCase>(GetAllDepartmentsUseCaseName).call();
        setDepartments(response);
        console.log("🚀 ~ file: EditProfilePage.tsx:117 ~ getDepartaments ~ response:", response);
    };

    const getCitiesByDeparment = async (departmentId: string) => {
        const response = await di
            .get<GetCitiesByDepartmentIdUseCase>(GetCitiesByDepartmentIdUseCaseName)
            .call(departmentId);
        setCitiesByDepartment(response);
        console.log("🚀 ~ file: EditProfilePage.tsx:133 ~ getCitiesByDeparment ~ response:", response);
    };

    useEffect(() => {
        getDepartaments();
    }, []);

    useEffect(() => {
        setValue("email", user?.email);
        setValue("name", user?.name);
    }, []);

    return (
        <Layout>
            <form className="quote_your_car_page py-5" onSubmit={handleSubmit(_handleOnSubmit)}>
                <div className="bg_image">
                    <Icons.TwoTrapecios />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <div onClick={_handleGoBack}>
                                <Icons.BackArrowCircle />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="side side_top mb-0 mt-3 mt-md-0">
                                <h1>
                                    Edita
                                    <span className="text_italic text_orange"> tu perfil</span>
                                </h1>
                                <h5>Edita la informacion de tu nombre de usuario & gmail</h5>
                            </div>
                            <div className="card mt-4 p-4 position-relative">
                                {loading && (
                                    <div className="loading_container">
                                        <LoadingComponent />
                                    </div>
                                )}
                                <div className="card-body">
                                    <HorizontalSteperComponent
                                        steps={["Datos personales"]}
                                        currentStep={currentStep}
                                        changeStep={setCurrentStep}
                                    >
                                        {currentStep == 0 ? (
                                            <div className="row">
                                                <div className="col-md-6 my-2">
                                                    <div className="form-group">
                                                        <label className="mandatory">Nombre de usuario</label>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            className="form-control"
                                                            placeholder="Usuario"
                                                            {...register(
                                                                "name",
                                                                Validators({
                                                                    required: true,
                                                                    minLength: 2,
                                                                    maxLength: 20,
                                                                })
                                                            )}
                                                        />
                                                        <ErrorMessage as="aside" errors={errors} name="name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <div className="form-group">
                                                        <label className="mandatory">Nuevo nombre de usuario</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Usuario"
                                                            {...register(
                                                                "userName",
                                                                Validators({
                                                                    required: true,
                                                                    minLength: 2,
                                                                    maxLength: 20,
                                                                })
                                                            )}
                                                        />
                                                        <ErrorMessage as="aside" errors={errors} name="userName" />
                                                    </div>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <div className="form-group">
                                                        <label>Gmail</label>
                                                        <input
                                                            disabled
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="nombre de compañía"
                                                            {...register(
                                                                "email",
                                                                Validators({
                                                                    maxLength: 50,
                                                                })
                                                            )}
                                                        />
                                                        <ErrorMessage as="aside" errors={errors} name="email" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <div className="form-group">
                                                        <label className="mandatory">Nuevo gmail</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="número de teléfono"
                                                            {...register(
                                                                "newGmail",
                                                                Validators({
                                                                    required: true,
                                                                    email: true,
                                                                })
                                                            )}
                                                        />
                                                        <ErrorMessage as="aside" errors={errors} name="newGmail" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <div className="form-group">
                                                        <label className="mandatory">Confirmar nuevo gmail</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="ejemplo@gmail.com"
                                                            {...register(
                                                                "contact.email",
                                                                Validators({
                                                                    required: true,
                                                                    email: true,
                                                                    mustBeEqual: getValues("newGmail"),
                                                                })
                                                            )}
                                                        />
                                                        <ErrorMessage as="aside" errors={errors} name="contact.email" />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {currentStep == 1 ? <></> : <></>}
                                    </HorizontalSteperComponent>
                                    <div className="d-flex justify-content-md-end mt-3">
                                        <button type="submit" className="btn btn_orange">
                                            Guardar
                                            <Icons.ArrowCircle />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default EditProfilePage;
