import { useEffect, useRef, useState } from "react";

const useMovilidad = () => {
    const formRefMovilidad = useRef<HTMLDivElement | null>(null);
    const formRefMovilidad1 = useRef<HTMLDivElement | null>(null);
    const formRefMovilidad2 = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scriptContent = `
            !function(a,m,o,c,r,m){
                a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}};
                a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])};
                a[o+r]({id:"1430767",hash:"95e5a42123ada7b6dccb64416d8aecd1",locale:"es"});
                a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])};
            }(window,0,"amo_forms_","params","load","loaded");
        `;

        const inlineScript = document.createElement("script");
        inlineScript.innerHTML = scriptContent;
        document.body.appendChild(inlineScript);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1430767";
        kommoScript.async = true;
        kommoScript.src = `https://forms.kommo.com/forms/assets/js/amoforms.js?${Date.now()}`;

        const timeoutId = setTimeout(() => {
            if (formRefMovilidad.current) {
                formRefMovilidad.current.innerHTML = ""; // Limpia antes de insertar
                formRefMovilidad.current.appendChild(kommoScript);
            }
            if (formRefMovilidad1.current) {
                formRefMovilidad1.current.innerHTML = ""; // Limpia antes de insertar
                formRefMovilidad1.current.appendChild(kommoScript);
            }
            if (formRefMovilidad2.current) {
                formRefMovilidad2.current.innerHTML = ""; // Limpia antes de insertar
                formRefMovilidad2.current.appendChild(kommoScript);
            }
        }, 500);

        return () => {
            document.body.removeChild(inlineScript);
            clearTimeout(timeoutId);
        };
    }, []);

    return { formRefMovilidad, formRefMovilidad1, formRefMovilidad2 };
};

export default useMovilidad;
