import { ErrorMessage } from "@hookform/error-message";
import Icons from "../../../../../assets/Icons";
import "./ModalAmountStyles.scss";
import { useForm } from "react-hook-form";
import di from "../../../../../../../di/DependencyInjection";
import BookACarUseCase, { BookACarUseCaseName } from "../../../../../../../domain/use_cases/book/BookACarUseCase";
import Validators from "../../../../../../utils/Validators";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../../../../components/Button";

interface ModalAmountProps {
    close: () => void;
    id: string;
    carValue: number;
    info: {
        title: string;
        paragraph: string;
        bottonOne: boolean;
        bottonTwo: boolean;
    };
}

export default function ModalAmount({ close, id, carValue, info }: ModalAmountProps) {
    console.log("🚀 ~ file: index.tsx:23 ~ ModalAmount ~ carValue:", carValue);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
        setError,
    } = useForm();
    const terms = watch("terms");
    const amount = watch("amount");
    const [ip, setIp] = useState("");

    useEffect(() => {
        setValue("payment", "wompi");
    }, []);

    useEffect(() => {
        if (watch("payment") == "ecollect" && parseFloat(formatValue(amount?.toString().trimStart())) > 15000000) {
            setValue("payment", "wompi");
        }
    }, [amount]);

    function formatValue(value: string): string {
        const plainValue = value?.replace(/[\.,\$]/g, "");
        const formattedValue = plainValue + ".00";
        return formattedValue;
    }

    const payment = () => {
        if (watch("payment") === "wompi") {
            wompi();
        } else {
            bookCar();
        }
    };

    const bookCar = async () => {
        console.log("AMOUNT____", formatValue(amount.toString()));
        if (!terms) {
            return;
        }

        if (parseFloat(formatValue(amount.toString().trimStart())) < 1000000.0) {
            // setError("amount", { message: "El valor mínimo para separar el vehículo es de $2.000.000 COP." });
            return;
        }

        try {
            const response = await window.fetch("https://api.wcar.online/api/scheduling-review/create/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    car: id,
                    totalToPay: parseFloat(formatValue(getValues().amount.toString().trimStart())),
                }),
            });
            const data = await response.json();
            // @ts-ignore
            window.location.href = data?.data_eccolect?.eCollectUrl;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchIp = async () => {
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            const data = await response.json();
            console.warn("ip", data.ip);
            setIp(data.ip);
        } catch (error) {
            console.error("Error al obtener la IP:", error);
        }
    };

    const getLocalIP = async () => {
        const rtc = new RTCPeerConnection({
            iceServers: [],
        });

        rtc.createDataChannel("");
        rtc.createOffer()
            .then((offer) => rtc.setLocalDescription(offer))
            .catch((error) => console.error(error));

        rtc.onicecandidate = (event) => {
            if (event && event.candidate && event.candidate.candidate) {
                const candidate = event.candidate.candidate;
                const regex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
                const ipAddress = candidate.match(regex);
                console.warn("ipAddress", ipAddress);
                // setLocalIp(ipAddress);
                rtc.close();
            }
        };
    };

    useEffect(() => {
        fetchIp();
    }, []);

    const car = async () => {
        if (
            getValues().terms &&
            parseFloat(formatValue(amount?.toString().trimStart())) < 15000001 &&
            parseFloat(formatValue(amount?.toString().trimStart())) > 999999
        ) {
            try {
                const response = await window.fetch("https://api.wcar.online/api/garantie-security/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: "Financiar",
                        car: id ? id : "",
                        SrvCode: "1004",
                        totalToPay: parseFloat(formatValue(getValues().amount.toString().trimStart())),
                    }),
                });
                const data = await response.json();
                console.log(data);
                // @ts-ignore
                window.location.href = data?.data_eccolect?.eCollectUrl;
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        setError("amount", { message: "" });
    }, [amount]);

    // const wompi2 = async () => {
    //     const amount = parseInt(watch("amount")?.replace(/[\.,\$]/g, "")) * 100;
    //     // const string = `${id}${amount}COPprod_integrity_u8xP4CrXnFryEqyCWG1b1yIjH4MWz3US`;
    //     const string = `${id}${amount}COPtest_integrity_bAv6AmpC4X7esLtDwgRnzoY2diaLQCN4`;

    //     const encondedText = new TextEncoder().encode(string);
    //     const hashBuffer = await crypto.subtle.digest("SHA-256", encondedText);
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

    //     // window.location.href = `https://checkout.wompi.co/p/?public-key=pub_prod_Xo043E5bHNlbkBxKU3GAUwVr7YjC5rB5&currency=COP&amount-in-cents=${amount}&reference=${id}&signature%3Aintegrity=${hashHex}&redirect-url=https://www.wcar.co/carro/cita/separar/${id}`
    //     window.location.href = `https://checkout.wompi.co/p/?public-key=pub_test_fg4dbbdMU6gtVudUSuJy1k4ehjlVrycs&currency=COP&amount-in-cents=${amount}&reference=${id}&signature%3Aintegrity=${hashHex}&redirect-url=https://www.wcar.co/carro/cita/separar/${id}`;
    // };

    const wompi = async () => {
        // Parse the amount to cents
        const amount = parseInt(watch("amount")?.replace(/[\.,\$]/g, "")) * 100;

        // Create the string to be hashed
        // const string = `${id}${amount}COP${privateKey}`;
        const string = `${id}${amount}COPprod_integrity_u8xP4CrXnFryEqyCWG1b1yIjH4MWz3US`;

        // Encode the string
        const encondedText = new TextEncoder().encode(string);

        // Generate the SHA-256 hash
        const hashBuffer = await crypto.subtle.digest("SHA-256", encondedText);

        // Convert the hash to a hex string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

        // Redirect to Wompi checkout
        window.location.href = `https://checkout.wompi.co/p/?public-key=pub_prod_Xo043E5bHNlbkBxKU3GAUwVr7YjC5rB5&currency=COP&amount-in-cents=${amount}&reference=${id}&signature%3Aintegrity=${hashHex}&redirect-url=https://www.wcar.co/carro/cita/separar/${id}`;
    };

    return (
        <div className="box_modal_amount">
            <div className="closer">
                <form className="modal-amount-buy position-relative">
                    <div className="icon-close" onClick={close}>
                        <Icons.Clear />
                    </div>
                    <h4 className="modal-title text-black" dangerouslySetInnerHTML={{ __html: info.title ?? "" }}></h4>
                    <p className="text-center px-5 fw-bold fs-6 mt-3">{info.paragraph}</p>
                    <div className="form-group mt-4 input-box">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Monto"
                            {...register("amount", Validators({ price: true, minValue: 1000000, maxValue: carValue }))}
                        />
                        <p style={{ color: "#888", fontSize: "14px", marginTop: "6px", marginLeft: "8px" }}>
                            Valor mínimo para separar el vehículo es de $1.000.000 COP.
                        </p>
                        <ErrorMessage as="aside" errors={errors} name="amount" />
                        <div className="d-flex justify-content-center align-items-center flex-wrap gap-4 my-4">
                            <input
                                {...register("payment")}
                                style={{ display: "none" }}
                                type="radio"
                                name="payment"
                                id="wompi"
                                value="wompi"
                            />
                            <label
                                className="hidden_checkbox"
                                style={{ width: 100, margin: 0, height: 40, cursor: "pointer" }}
                                htmlFor="wompi"
                            >
                                <img
                                    src="/assets/wompi.png"
                                    alt="wompi"
                                    className="w-100 h-100"
                                    style={{
                                        filter: `drop-shadow(0 0 10px ${
                                            watch("payment") === "wompi" ? "#ec671b" : "#00000000"
                                        })`,
                                        objectFit: "contain",
                                        opacity: `${watch("payment") === "wompi" ? "1" : "0.5"}`,
                                    }}
                                />
                            </label>
                            <input
                                {...register("payment")}
                                disabled={
                                    parseFloat(formatValue(amount?.toString().trimStart())) > 15000000 ? true : false
                                }
                                style={{ display: "none" }}
                                type="radio"
                                name="payment"
                                id="ecollect"
                                value="ecollect"
                            />
                            <label
                                className="hidden_checkbox"
                                style={{ width: 100, margin: 0, height: 40, cursor: "pointer" }}
                                htmlFor="ecollect"
                            >
                                <img
                                    src="/assets/ECOLLECT.png"
                                    alt="ecollect"
                                    className="w-100 h-100"
                                    style={{
                                        filter: `drop-shadow(0 0 10px ${
                                            watch("payment") === "wompi" ? "#00000000" : "#ec671b"
                                        })`,
                                        objectFit: "contain",
                                        opacity: `${watch("payment") !== "wompi" ? "1" : "0.5"}`,
                                    }}
                                />
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={() => {
                                    console.log(acceptedTerms);
                                    setAcceptedTerms(!acceptedTerms);
                                    setValue("terms", !acceptedTerms);
                                }}
                                {...register("terms")}
                            />
                            <label className="form-check-label">
                                <Link target="_blank" to={"/politicas-comprador"} className="text_underline text_gray">
                                    Acepto términos y condiciones
                                </Link>
                            </label>
                            <ErrorMessage as="aside" errors={errors} name="terms" />
                        </div>
                    </div>
                    <div className="modal-buttons">
                        {info.bottonOne && (
                            <Button
                                disabled={
                                    !(
                                        getValues().terms &&
                                        parseFloat(formatValue(amount?.toString().trimStart())) > 999999
                                    )
                                }
                                label="Separar Vehículo"
                                className={`${
                                    getValues().terms &&
                                    parseFloat(formatValue(amount?.toString().trimStart())) > 999999
                                        ? ""
                                        : "bg-color"
                                }`}
                                onClick={() => (ip == "129.222.1.17" ? null : payment())}
                            />
                        )}
                        {info.bottonTwo && (
                            <Button
                                disabled={
                                    !(
                                        getValues().terms &&
                                        parseFloat(formatValue(amount?.toString().trimStart())) < 15000001 &&
                                        parseFloat(formatValue(amount?.toString().trimStart())) > 999999
                                    )
                                }
                                label="Financiar Separación"
                                variant="secondary"
                                className={`${
                                    getValues().terms &&
                                    parseFloat(formatValue(amount?.toString().trimStart())) < 15000001 &&
                                    parseFloat(formatValue(amount?.toString().trimStart())) > 999999
                                        ? ""
                                        : "color"
                                }`}
                                onClick={() => (ip == "129.222.1.17" ? null : car())}
                            />
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}
