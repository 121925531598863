import { useEffect, useRef, useState } from "react";

const useSalud = () => {
    const formRefSalud = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scriptContent = `
            !function(a,m,o,c,r,m){
                a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}};
                a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])};
                a[o+r]({id:"1430843",hash:"f24ff4cfafe21b43a7a57083ec75cbae",locale:"es"});
                a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])};
            }(window,0,"amo_forms_","params","load","loaded");
        `;

        const inlineScript = document.createElement("script");
        inlineScript.innerHTML = scriptContent;
        document.body.appendChild(inlineScript);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1430843";
        kommoScript.async = true;
        kommoScript.charset = "utf-8";
        kommoScript.src = `https://forms.kommo.com/forms/assets/js/amoforms.js?${Date.now()}`;

        const timeoutId = setTimeout(() => {
            if (formRefSalud.current) {
                formRefSalud.current.innerHTML = ""; // Limpia antes de insertar
                formRefSalud.current.appendChild(kommoScript);
            }
        }, 500);

        return () => {
            document.body.removeChild(inlineScript);
            clearTimeout(timeoutId);
        };
    }, []);

    return { formRefSalud };
};

export default useSalud;
