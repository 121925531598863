import { useEffect, useRef, useState } from "react";

const useKommoForm = (openModal: boolean) => {
    const formRef = useRef<HTMLDivElement | null>(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const scriptContent = `
                !function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1430755",hash:"11ee1081c9adb1476ce469ca74199242",locale:"es"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");
            `;

        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1430755";
        kommoScript.async = true;
        kommoScript.charset = "utf-8";
        kommoScript.src = "https://forms.kommo.com/forms/assets/js/amoforms.js?1738201693";

        const timeoutId = setTimeout(() => {
            if (formRef.current) {
                formRef.current.innerHTML = ""; // Limpia antes de insertar
                formRef.current.appendChild(kommoScript);
            }
        }, 500);

        return () => {
            document.body.removeChild(script);
            clearTimeout(timeoutId);
            setScriptLoaded(false);
        };
    }, []);

    return { formRef };
};

export default useKommoForm;
