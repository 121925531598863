import "./AboutUsPageStyles.scss";
import React, { FC, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import Icons from "../../assets/Icons";
import { Link } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import CommenstLineComponent from "../../components/commentsLine/CommentsLineComponent";
import CardVisionComponent from "./components/cardVision/CardVisionComponent";
import CardContactComponent from "../../components/cardContact/CardContactComponent";
import AccordeonComponent from "../../components/accordeon/AccordeonComponent";
import GetAllFrequentQuestionsAboutUsUseCase, {
    GetAllFrequentQuestionsAboutUsUseCaseName,
} from "../../../../domain/use_cases/frequentQuestion/GetAllFrequentQuestionsAboutUsUseCase";
import di from "../../../../di/DependencyInjection";
import FrequentQuestionEntity from "../../../../domain/entities/FrequentQuestionEntity";
import { Helmet } from "react-helmet-async";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import OrangeFestModal from "../../components/OrangeFestModal/OrangeFestModal";
import { CarouselOurPeople } from "../../components/carouselOurPeople/carouselOurPeople";

const AboutUsPage: FC<{}> = () => {
    const [frequentQuestions, setFrequentQuestions] = useState<FrequentQuestionEntity[]>([]);
    const [showOrangeBanner, setShowOrangeBanner] = React.useState<boolean>(
        window.localStorage.getItem("orangeFestModal") ? false : true
    );

    const _getFrequentQuestions = async () => {
        try {
            const respose = await di
                .get<GetAllFrequentQuestionsAboutUsUseCase>(GetAllFrequentQuestionsAboutUsUseCaseName)
                .call();
            setFrequentQuestions(respose);
        } catch (error) {}
    };

    useEffect(() => {
        _getFrequentQuestions();
    }, []);

    const [carsInfo, setCarsInfo] = useState([]);
    useEffect(() => {
        fetch(`https://api.wcar.online/api/advisors/`)
            .then((res) => res.json())
            .then((res) => setCarsInfo(res))
            .catch((e) => console.error(e));
    }, []);

    return (
        <div className="about_us_page">
            <Helmet>
                <title>Nuestra empresa wcar #1 en Transparencia brutal</title>
                <meta
                    name="description"
                    content="En Latam el mercado de autos usados es de 6 millones de transacciones al año. wcar sas es el primer phygital ecosystem"
                />
                <meta
                    name="keywords"
                    content="Qué dicen de wcar, Visión de wcar, Misión de wcar, innovación disruptiva, precios razonables"
                />
                <link rel="canonical" href="https://wcar.co" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Layout>
                <section className="w-100 position-relative section_1">
                    <picture className="w-100">
                        <source
                            srcSet="/assets/aboutUs/bg_1_mobile.jpg"
                            type="image/jpg"
                            media="(max-width: 768px)"
                            className="w-100"
                        />
                        <img srcSet="/assets/aboutUs/bg_1.jpg" className="img-fluid w-100" />
                    </picture>
                    <img
                        src="/assets/aboutUs/src_1.png"
                        title="Nuestra empresa wcar #1 en Transparencia brutal"
                        alt="Nuestra empresa wcar #1 en Transparencia brutal"
                        className="src_1"
                    />
                </section>
                <section className="section_2 pt-5 pt-md-0">
                    <div className="container our_company">
                        <div className="row d-flex flex-wrap">
                            <div className="col-md-5 d-none d-md-block position-relative">
                                <img
                                    src="/assets/aboutUs/bg_2.jpg"
                                    className="bg_2 img-fluid"
                                    title="Nuestra empresa wcar #1 en Transparencia brutal"
                                    alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                />
                            </div>
                            <div className="col-md-7 bg_white">
                                <div className="info">
                                    <div
                                        className="side side_top text_reduc bg_whiteed text_gray"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Por qué nosotros
                                    </div>
                                    <h1
                                        style={{
                                            fontSize: "36px",
                                            lineHeight: "44px",
                                            fontWeight: 700,
                                            color: "#1E1E1E",
                                        }}
                                    >
                                        Nuestra Empresa
                                    </h1>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            fontWeight: 500,
                                            color: "#666C89",
                                            marginTop: "12px",
                                        }}
                                    >
                                        Cerca de 12 millones de personas en Latinoamérica frente a la compra y venta de
                                        vehículos usados sufren por la debilidad de las legislaciones y la ineficacia
                                        institucional, dejando a los consumidores en una vulnerabilidad alarmante. Este
                                        desafío trasciende todos los niveles socioeconómicos, forzando a los ciudadanos
                                        a enfrentar riesgos significativos en transacciones de vehículos, que a menudo
                                        es su segundo activo más preciado. Las empresas tradicionales frecuentemente se
                                        limitan a cumplir solo con la legislación básica, descuidando tanto las
                                        necesidades del cliente como su responsabilidad social.
                                        <br />
                                        <br />
                                        Aquí es donde wcar emerge no solo como una empresa, sino como un movimiento que
                                        busca revolucionar la compra-venta de autos usados en Latinoamérica. Nuestro
                                        enfoque trasciende la mera venta, enfocándonos en transacciones seguras y
                                        empoderadoras para el consumidor. Nos posicionamos en el núcleo de la
                                        transformación del sector, fusionando transparencia brutal y tecnología al
                                        alcance de todos para otorgar al usuario control total y claridad en cada
                                        transacción.
                                    </p>
                                    <div className="mt-4">
                                        <ButtonWithIcon to={routes.contact.relativePath} text="Contacta un asesor" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sized_infinited d-none d-md-block c2">
                        <img
                            src="assets/recs/lines_box_large.png"
                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                            className="img_fluid"
                        />
                    </div>
                    <div className="container our_mission py-5">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="side side_top side_blue_neon">
                                    <h2>
                                        <span className="text_bold text_black text_md_white">Misión</span>{" "}
                                        <br className="d-none d-md-block" />
                                        <span className="text_orange text_md_white">& visión</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-md-5 my-3">
                                <CardVisionComponent
                                    minTitle="Misión"
                                    title="¿Cuál es nuestra misión en el mundo?"
                                    image={
                                        <div className="text_orange">
                                            {" "}
                                            <Icons.StarCircle />{" "}
                                        </div>
                                    }
                                    description="WCAR emerge no solo como una empresa, sino como un movimiento que busca revolucionar la compra y venta de autos usados en Latinoamérica. Nuestra misión trasciende la mera venta, enfocándonos en transacciones seguras y empoderadoras para el consumidor. Nos posicionamos en el núcleo de la transformación del sector, fusionando transparencia brutal y tecnología al alcance de todos para otorgar al usuario control total y claridad en cada transacción."
                                />
                            </div>
                            <div className="col-md-5 my-3">
                                <CardVisionComponent
                                    minTitle="Visión"
                                    title="Y nuestra visión… "
                                    image={
                                        <div className="text_orange">
                                            {" "}
                                            <Icons.HeartFilled />{" "}
                                        </div>
                                    }
                                    description={
                                        <p>
                                            Convertirnos en el referente en LATAM de la industria a través de la
                                            transparencia.
                                            <br />
                                            Nuestra visión es posicionarnos entre las tres principales compañías en
                                            LATAM de la industria automotriz, elevando la consciencia social. Buscamos
                                            impactar no solo a nuestros clientes, sino a toda la comunidad involucrada
                                            en la compra-venta de vehículos, fomentando la importancia de acceder a
                                            información detallada. Nuestro objetivo es disminuir la asimetría de
                                            información, reducir fricciones y eliminar costos ocultos, garantizando
                                            transacciones transparentes, informadas y justas.
                                        </p>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_2 pt-5 pt-md-0" style={{ marginTop: 210, backgroundColor: "#000" }}>
                    <div className="container our_company">
                        <div className="row d-flex flex-wrap">
                            <div className="col-md-7 bg_white"></div>
                        </div>
                    </div>

                    <div className="container our_mission py-5">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="side side_top side_blue_neon">
                                    <h2>
                                        <span className="text_bold text_black text_md_white text_orange">Nuestro</span>{" "}
                                        <br className="d-none d-md-block" />
                                        <span className="text_orange text_md_orange">Equipo</span>
                                    </h2>
                                </div>
                            </div>
                            <p className="text_md_white fs-5">
                                En wcar, nuestros asesores de venta y compra siempre estarán atentos para brindarte un
                                excelente servicio.
                            </p>
                            <div className="col-md-12 my-3">
                                <CarouselOurPeople cars={carsInfo} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_3 py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12">
                                        <img
                                            src="/assets/aboutUs/asset_1.png"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                    </div>
                                    <div className="col-6 d-flex flex-column justify-content-space-between">
                                        <img
                                            src="/assets/aboutUs/asset_2.png"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                        <img
                                            src="/assets/aboutUs/asset_3.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100 flex-grow-1"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src="/assets/aboutUs/asset_4.png"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100 asset_4"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-6">
                                        <img
                                            src="/assets/aboutUs/asset_5.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src="/assets/aboutUs/asset_6.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <img
                                            src="/assets/aboutUs/asset_7.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src="/assets/aboutUs/asset_8.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <img
                                            src="/assets/aboutUs/asset_9.jpg"
                                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                                            className="img-fluid mt-3 object_cover w-100 asset_4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CommenstLineComponent />
                <section className="section_4 py-5 position-relative">
                    <div className="sized_infinited d-none d-md-block bg_3 c4">
                        <img
                            src="/assets/aboutUs/bg_3.jpg"
                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                            className="img-fluid h-100 w-100 object_cover"
                        />
                    </div>
                    <div className="container">
                        <div className="row d-flex">
                            <div className="col-md-6">
                                <AccordeonComponent
                                    title={
                                        <h2 className="font_bold h3">
                                            Nuestros <span className="text_orange text_italic">valores</span>
                                        </h2>
                                    }
                                    options={frequentQuestions.map((frequentQuestion) => {
                                        return {
                                            title: frequentQuestion.question,
                                            content: frequentQuestion.answer,
                                        };
                                    })}
                                />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-4 d-flex align-items-center">
                                <CardContactComponent />
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none w-100">
                        <img
                            src="/assets/aboutUs/bg_3_mobile.jpg"
                            title="Nuestra empresa wcar #1 en Transparencia brutal"
                            alt="Nuestra empresa wcar #1 en Transparencia brutal"
                            className="img_fluid"
                        />
                    </div>
                </section>
                {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
            </Layout>
        </div>
    );
};

export default AboutUsPage;
